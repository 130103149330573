const house = [
    {
        path: '/user/manage',
        name: 'userManage',
        component: () => import('@/view/user/manage'),
    },
    {
        path: '/user/manage/details',
        name: 'userManageDetails',
        component: () => import('@/view/user/manage/details'),
    },
    {
        path: '/user/manage/details/detail',
        name: 'userManageDetailsDetail',
        component: () => import('@/view/user/manage/details/detail'),
    },


]
export default house