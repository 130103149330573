const indexHome = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/view/index/index'),
    },
    {
        path: '/password',
        name: 'password',
        component: () => import('@/pages/password')
    }
]
export default indexHome