const house = [

    // {
    //     path: '/partner/manage',
    //     name: 'partnerManage',
    //     component: () => import('@/view/region/commission')
    // },
    // {
    //     path: '/partner/manage/create',
    //     name: 'partnerManageCreate',
    //     component: () => import('@/view/region/commission/create')
    // },

    {
        path: '/partner/manage',
        name: 'partnerManage',
        component: () => import('@/view/partner/manage')
    },
    {
        path: '/partner/manage/create',
        name: 'partnerManageCreate',
        component: () => import('@/view/partner/manage/create')
    },
    {
        path: '/partner/manage/revenue',
        name: 'partnerManageRevenue',
        component: () => import('@/view/partner/manage/revenue')
    },
    {
        path: '/partner/manage/bindingRelationship',
        name: 'partnerManageBindingRelationship',
        component: () => import('@/view/partner/manage/bindingRelationship')
    },
    {
        path: '/partner/manage/atomicFission',
        name: 'partnerManageAtomicFission',
        component: () => import('@/view/partner/manage/atomicFission')
    },


    {
        path: '/partner/bind',
        name: 'partnerBind',
        component: () => import('@/view/partner/bindingRelationship')
    },
    {
        path: '/partner/detail',
        name: 'partnerDetail',
        component: () => import('@/view/partner/revenue')
    },
    {
        path: '/partner/withdraw',
        name: 'partnerWithdraw',
        component: () => import('@/view/partner/withdraw')
    },
    {
        path: "/partner/type",
        name: "partnerType",
        component: () => import("@/view/partner/type"),
    },
    {
        path: "/partner/examine",
        name: "partnerExamine",
        component: () => import("@/view/partner/examine"),
    },

]
export default house