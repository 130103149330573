const house = [
  {
    path: "/order/setting",
    name: "orderSetting",
    component: () => import("@/view/order/setting"),
  },
  {
    path: "/order/manage",
    name: "orderManage",
    component: () => import("@/view/order/manage"),
  },
  {
    path: "/order/manage/detail",
    name: "orderManageDetail",
    component: () => import("@/view/order/manage/detail"),
  },
  {
    path: "/order/manage/look",
    name: "orderManageLook",
    component: () => import("@/view/order/manage/look"),
  },
];
export default house;
