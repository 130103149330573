import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import pages from "./pages";
import indexHome from "./view";
import house from "./view/house";
import system from "./view/system";
import home from "./view/home";
import level from "./view/level";
import balance from "./view/balance";
import deposit from "./view/deposit";
import integral from "./view/integral";
import coupon from "./view/coupon";
import car from "./view/car";
import region from "./view/region";
import log from "./view/log";
import setting from "./view/setting";
import scan from "./view/scan";
import partner from "./view/partner";
import order from "./view/order";
import user from './view/user'
import worker from './view/worker'
import fault from './view/fault'
import sale from './view/sale'
import data from './view/data'
import finance from './view/finance'
import message from './view/message'
// message
// finance

// 首页
import index from "@/pages/index/index";

// view 内容

export default new Router({
  // mode: 'history',//去掉#号、
  mode: "hash", //保留#号、
  routes: [
    ...pages,
    {
      path: "/",
      component: index,
      children: [

        ...indexHome,
        ...house,
        ...home,
        ...level,
        ...user,
        ...balance,
        ...fault,
        ...sale,
        ...deposit,
        ...integral,
        ...coupon,
        ...car,
        ...region,
        ...worker,
        ...partner,
        ...order,
        ...scan, // 扫码开车白名单管理
        ...finance,//财务报表白名单管理
        ...message, //消息中心
        ...setting, // 其他设置管理
        ...log, // 系统日志
        // ------------------------------------------------------ 系统管理
        ...system,
      ],
    },
    ...data,
  ],
});
