<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapMutations,mapGetters } from 'vuex'
export default {
  name: 'App',
  watch:{
    '$route'(to){
      this.$store.commit("setRouter",to.path);
      // sessionStorage.setItem("store", JSON.stringify(this.$store.state));
      if(!this.token && this.userInfo.headPortrait && this.userInfo.id){
        const cookie = document.cookie.split(';');
        cookie.forEach(item=>{
          const arr = item.split('=');
          if(arr[0] === 'Authorization'){
            this.setToken(arr[1])
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo','token'])
  },
  created() {
    this.global.SET_sessionStorage();
  },
  methods: {
    ...mapMutations(['setToken'])
  }
}
</script>

<style>
  @import  './style/public.css';
  @import  './style/font/iconfont.css';
  *{
    margin: 0;
    padding: 0;
  }
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
</style>
