const house = [
    {
        path: '/coupon/manage',
        name: 'couponManage',
        component: () => import('@/view/coupon/manage'),
    },
    {
        path: '/coupon/manage/create',
        name: 'couponManageCreate',
        component: () => import('@/view/coupon/manage/create'),
    },
    {
        path: '/coupon/manage/details',
        name: 'couponManageDetails',
        component: () => import('@/view/coupon/manage/details'),
    },
    {
        path: '/coupon/use',
        name: 'couponUse',
        component: () => import('@/view/coupon/use'),
    },
    {
        path: '/coupon/send',
        name: 'couponSend',
        component: () => import('@/view/coupon/send'),
    },
    {
        path: '/coupon/send/details',
        name: 'couponSendDetails',
        component: () => import('@/view/coupon/send/details'),
    },
    {
        path: '/coupon/up',
        name: 'couponUp',
        component: () => import('@/view/coupon/up'),
    },
    {
        path: '/coupon/relate',
        name: 'couponRelate',
        component: () => import('@/view/coupon/relate'),
    },
]
export default house