const log = [
  {
    path: "/finance/while",
    name: "financeWhite",
    component: () => import("@/view/finance/while"),
  },
  {
    path: "/finance/while/create",
    name: "financeWhiteCreate",
    component: () => import("@/view/finance/while/create"),
  },
  // {
  //   path: "/finance/while/open",
  //   name: "financeWhiteOpen",
  //   component: () => import("@/view/finance/while/open"),
  // },
  // {
  //   path: "/finance/open",
  //   name: "financeOpen",
  //   component: () => import("@/view/finance/open"),
  // },
];
export default log;
