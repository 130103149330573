const message = [
    {
      path: "/message/manage",
      name: "messageManage",
      component: () => import("@/view/message/manage"),
    },
    {
        path: "/message/manage/afterSalesNews",
        name: "messageAfterSalesNews",
        component: () => import("@/view/message/manage/afterSalesNews"),
      },
      {
        path: "/message/manage/faultMessages",
        name: "messageFaultMessages",
        component: () => import("@/view/message/manage/faultMessages"),
      },
      {
        path: "/message/manage/partnerInformation",
        name: "messagePartnerInformation",
        component: () => import("@/view/message/manage/partnerInformation"),
      },
      {
        path: "/message/manage/depositMessage",
        name: "messageDepositMessage",
        component: () => import("@/view/message/manage/depositMessage"),
      },
      {
        path: "/message/manage/otherMessages",
        name: "messageOtherMessages",
        component: () => import("@/view/message/manage/otherMessages"),
      },
	  {
	    path: "/message/manage/other",
	    name: "messageOther",
	    component: () => import("@/view/message/manage/other"),
	  },
    {
      path: "/message/receive",
      name: "messageReceive",
      component: () => import("@/view/message/receive"),
    },
  ];
  export default message;
  