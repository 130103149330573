const house = [
    {
        path: '/home/banner',
        name: 'banner',
        component: () => import('@/view/home/banner'),
    },
    {
        path: '/home/banner/create',
        name: 'homeBannerCreate',
        component: () => import('@/view/home/banner/create')
    },
    {
        path: '/home/frame',
        name: 'frame',
        component: () => import('@/view/home/frame'),
    },
    {
        path: '/home/frame/create',
        name: 'homeFrameCreate',
        component: () => import('@/view/home/frame/create')
    },
]
export default house