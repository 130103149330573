const log = [
  {
    path: "/scan/white",
    name: "scanWhite",
    component: () => import("@/view/scan/white"),
  },
  {
    path: "/scan/white/create",
    name: "scanWhiteCreate",
    component: () => import("@/view/scan/white/create"),
  },
  {
    path: "/scan/white/open",
    name: "scanWhiteOpen",
    component: () => import("@/view/scan/white/open"),
  },
  {
    path: "/scan/open",
    name: "scanOpen",
    component: () => import("@/view/scan/open"),
  },
];
export default log;
