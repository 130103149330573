const house = [
    {
        path: '/level/intimacy',
        name: 'banner',
        component: () => import('@/view/level/intimacy'),
    },
    {
        path: '/level/discount',
        name: 'banner',
        component: () => import('@/view/level/discount'),
    }
]
export default house