const house = [
    {
        path: '/deposit/setting',
        name: 'depositSetting',
        component: () => import('@/view/deposit/setting'),
    },
    {
        path: '/deposit/record',
        name: 'depositRecord',
        component: () => import('@/view/deposit/record'),
    },
    {
        path: '/deposit/apply',
        name: 'depositApply',
        component: () => import('@/view/deposit/apply')
    }
]
export default house