const user = {
    state: {
        userInfo: {},
        Token: '',
    },
    getters: {
        userInfo(state) {
            return state.userInfo
        },
        token(state) {
            return state.Token
        }
    },
    // mapGetters
    mutations: {
        setToken(state,info){
            state.Token = info
            // state.Token = 'info'
        },
        setUserInfo(state,info){
            state.userInfo = info
        }
    }

}
export default user
