const house = [
    {
        path: '/car/type',
        name: 'carType',
        component: () => import('@/view/car/type'),
    },
    {
        path: '/car/bind',
        name: 'carbind',
        component: () => import('@/view/car/bind'),
    },
    {
        path: '/car/bind/create',
        name: 'carbindCreate',
        component: () => import('@/view/car/bind/create'),
    },
    {
        path: '/car/manage',
        name: 'carManage',
        component: () => import('@/view/car/manage'),
    },
    {
        path: '/car/manage/create',
        name: 'carManageCreate',
        component: () => import('@/view/car/manage/create'),
    },
    {
        path: '/car/manage/details',
        name: 'carManageDetails',
        component: () => import('@/view/car/manage/details/index.vue'),
    },
]
export default house