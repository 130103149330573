import Vue from 'vue'
import ECharts from 'vue-echarts';
import { use } from 'echarts/core';
import App from './App.vue'
import Vuex from 'vuex'
import {
  CanvasRenderer,
} from 'echarts/renderers';
import {
  BarChart,
  LineChart,
  PieChart,
  ScatterChart,
  RadarChart,
  MapChart,
} from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  LineChart,
  PieChart,
  ScatterChart,
  RadarChart,
  MapChart,
]);
Vue.component('v-chart', ECharts);
import store from './store';
Vue.use(Vuex);

import router from './router'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)


// Avue引用
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);

import AvueUeditor from 'avue-plugin-ueditor'
Vue.use(AvueUeditor);

// 管理后台标题
Vue.prototype.$title = '中信文旅平台管理后台'

Vue.config.productionTip = false

import { actionUrl, videoUrl } from '@/config/public';
Vue.prototype.actionUrl = actionUrl;
Vue.prototype.videoUrl = videoUrl;

import global from '@/utils/global';
Vue.prototype.global = global;

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.prototype.$TMap_Key = 'T7OBZ-GYOYS-2HVON-6HH2T-RK6H2-LFBRJ' // 腾讯地图Key

import pagesHeader from '@/components/a-pages/pagesHeader.vue';
Vue.component("pagesHeader", pagesHeader);

new Vue({
  router,
  store,
  data: {
    eventHub: new Vue()  //vue 全局事件(eventBus)设置
  },
  render: h => h(App),
}).$mount('#app')
